var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"book-inventory-i-o"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',[_c('v-row'),(_vm.isLoading)?_c('v-progress-linear',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-0 event-table",attrs:{"headers":_vm.headers,"items":_vm.filtered.data,"page":_vm.page,"items-per-page":1000000000,"hide-default-header":"","hide-default-footer":"","fixed-header":"","height":"70vh"},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(prop,index){return _c('th',{key:index},[_vm._v(_vm._s(prop.text))])}),0),_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('td',{key:header.text,staticClass:"select-book abc filter-event"},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[(header.value === 'subject')?_c('v-text-field',{staticClass:"text-field",class:'task-management-select',attrs:{"placeholder":"Search...","items":_vm.selectLocation,"dense":"","outlined":""},model:{value:(_vm.taskName),callback:function ($$v) {_vm.taskName=$$v},expression:"taskName"}}):(header.value === 'appointmentID')?_c('v-text-field',{staticClass:"text-field",class:'task-management-select',attrs:{"id":"input-appointmentID-fix","placeholder":"Search...","items":_vm.selectLocation,"dense":"","outlined":""},model:{value:(_vm.taskID),callback:function ($$v) {_vm.taskID=$$v},expression:"taskID"}}):(header.value === 'description')?_c('v-text-field',{staticClass:"text-field",class:'task-management-select',attrs:{"placeholder":"Search...","items":_vm.selectLocation,"dense":"","outlined":""},model:{value:(_vm.descriptions),callback:function ($$v) {_vm.descriptions=$$v},expression:"descriptions"}}):_vm._e(),(
                        _vm.filters.hasOwnProperty(header.value) &&
                        header.value !== 'description' &&
                        header.value !== 'appointmentID' &&
                        header.value !== 'subject'
                      )?_c('Autocomplete',{attrs:{"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":header.value === 'listAssigned'
                          ? _vm.groupColumnAssignList()
                          : _vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_vm._e()])}),0)])]}},{key:"body",fn:function(ref){return _vm._l((_vm.filtered.listDay),function(item,index1){return _c('tbody',{key:index1},_vm._l((item.data),function(event,index2){return _c('tr',{key:index2,on:{"click":function (e) { return _vm.showEvent(e, event); }}},[_c('td',[_vm._v(_vm._s(index2 === 0 ? _vm.formatDate(event.startDate) : ''))]),_c('td',{style:({ width: '50px' })},[_c('p',[_vm._v(_vm._s(index2 === 0 ? event.weekday : ''))])]),_c('td',[_vm._v(_vm._s(event.timeInDay))]),_c('td',[_c('span',{class:"cycle ",style:({ background: _vm.getEventColor(event) })}),_vm._v(_vm._s(event.categoryName)+" ")]),_c('td',[_vm._v(" "+_vm._s(event.subject)+" ")]),_c('td',[_vm._v(" "+_vm._s(event.appointmentID)+" ")]),_c('td',{staticClass:"limit-text"},[_vm._v(_vm._s(event.description))]),_c('td',{staticClass:"limit-text",style:({ width: '200px' })},_vm._l((event.listAssigned),function(assigned,index){return _c('p',{key:assigned.teacherID,staticClass:"ml-1"},[_vm._v(" "+_vm._s(index === event.listAssigned.length - 1 ? assigned.teacherName : assigned.teacherName + ',')+" ")])}),0),_c('td',[_vm._v(_vm._s(event.crtdUser))]),_c('td',[_vm._v(_vm._s(_vm._dayjs(event.crtdDate).format('DD/MM/YYYY')))]),_c('td',[_vm._v(_vm._s(event.taskPriority))]),_c('td',[_vm._v(_vm._s(event.isCompleted))]),_c('td',[_vm._v(_vm._s(event.visibility))])])}),0)})}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }